import { AsyncPipe, NgClass } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { DateTime } from 'luxon';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import packageJson from '../../../../package.json';
import { SiteConfigInterface } from '../models/config/site-config.interface';
import { IsDarkModeService } from '../services/is-dark-mode.service';
import { SiteConfigService } from '../services/site-config.service';
@Component({
  selector: 'app-site-footer',
  standalone: true,
  imports: [RouterLink, AsyncPipe, NgClass, FaIconComponent],
  templateUrl: './site-footer.component.html',
  styleUrl: './site-footer.component.scss',
})
export class SiteFooterComponent {
  year: number = DateTime.now().year;
  siteConfig!: SiteConfigInterface | null;
  siteConfigSub!: Subscription;
  isDarkMode$!: Observable<boolean>;
  customFooterColumn!: string;
  showSiteColumn: boolean = false;
  version = packageJson.version;

  constructor(
    public siteConfigService: SiteConfigService,
    private isDarkModeService: IsDarkModeService,
    public httpClient: HttpClient,
  ) {}

  async ngOnInit() {
    this.isDarkMode$ = this.isDarkModeService.isDarkMode;
    this.siteConfigSub = this.siteConfigService.updateSiteConfig.subscribe(async (siteConfig) => {
      this.siteConfig = siteConfig;
      if (this.siteConfig?.ngConfig.useCustomFooterColumnHtml) {
        this.showSiteColumn = true;
        this.customFooterColumn = await firstValueFrom(
          this.httpClient.get(
            environment.cdnEndpoint + '/assets/sites/' + this.siteConfig?.ngConfig.machineName + '/footer.html',
            {
              responseType: 'text',
            },
          ),
        );
      } else if (!this.siteConfig?.ngConfig.primarySite) {
        this.showSiteColumn = true;
      } else {
        this.showSiteColumn = false;
      }
    });
  }

  ngOnDestroy() {
    if (this.siteConfigSub) {
      this.siteConfigSub.unsubscribe();
    }
  }
}
